import React, { Component } from 'react';
import './StudentProfileEditable.css';
import meclogo from './../../Component/NavBar/headerlogo.png';
import axios from 'axios';
import $ from 'jquery';
import SpinnerLoad from '../../Component/SpinnerLoad/SpinnerLoad';
export class StudentProfileEditable extends Component {
    state = {
      
        studentdata:{
         id:"",
         student_name:"",
         phone_no:"", 
         department_id :"1",
         merit_position:"",
         student_email:"",
         hometown:"",
         school:"",
         college:"",
         bloodgroup:"O+",
         height:"",
         emergency_contact:"",
         batch_no:"12",
         dateofbirth:"",
         hall_no:"",
         sex:"",
         profile_image:"https://bootdey.com/img/Content/avatar/avatar7.png"
        },
       
        studentname:"",
        heightdata : ["4'1\"","4'2\"","4'3\"","4'4\"","4'5\"","4'6\"","4'7\"","4'8\"","4'9\"","4'10\"","4'11\"","5'","5'1\"","5'2\"","5'3\"","5'4\"","5'5\"","5'6\"","5'7\"","5'8\"","5'9\"","5'10\"","5'11\"","6'","6'1\"","6'2\"","6'3\"","6'4\"","6'5\"","6'6\"","6'7\"","6'8\"","6'9\"","6'10\"","6'11\""],
        submit:false,
        photoupload:false,
        hallname:["Omar Akushey Hall", "Muktijoddha Hall", "Taramon Bibi Hall"],
        loading:true,
        updatingButton:false,
        message:""
          
        
 
   }

 IsJsonString=(str)  => {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

   componentDidMount(){
    axios.defaults.headers.common['Authorization'] = 'Bearer '+localStorage.getItem("TOKEN_KEY");
    axios.get('/userprofiledata'  )
    .then(  (response) =>{
      console.log(response.data);
      this.setState({
          studentdata:{
            student_name:response.data.student_name,
            phone_no:response.data.phone_no, 
            department_id :response.data.department_id,
            merit_position:response.data.merit_position,
            student_email:response.data.student_email,
            hometown:response.data.hometown,
            school:response.data.school,
            college:response.data.college,
            bloodgroup:response.data.bloodgroup,
            height:response.data.height,
            emergency_contact:response.data.emergency_contact ,
            batch_no:response.data.batch_no,
            dateofbirth:response.data.dateofbirth,
            id : response.data.id,
            user_id: response.data.user_id,
            hall_no: response.data.hall_no,
            sex: response.data.sex,
            profile_image:  this.IsJsonString(response.data.profile_image) &&  response.data.profile_image!=null && response.data.profile_image!="" ?JSON.parse(response.data.profile_image).thumb:"https://bootdey.com/img/Content/avatar/avatar7.png"
            
            // "https://bootdey.com/img/Content/avatar/avatar7.png"
          },
          loading:false
      })
      
    })
    .catch(  (error)=> {
      console.log(error);
    });



   }
 
   toastHideController = () =>{
 
 this.setState({submit:false})
   }
 
 
   inputBoxChangeHandler = (e)=>{
     var oldData = this.state.studentdata;
     var newData = {
       ...oldData,
       [e.target.id]:e.target.value
     }
     this.setState({
       studentdata:newData,
       submit:false,
    
     })
 
   }
 
   _handleKeyDown = (e) => {
     if (e.key === 'Enter') {
       //  this.formSubmitHandler.bind()
       this.formSubmitHandler()
     }
   }
 
   formSubmitHandler = (e) =>{
    e.preventDefault()

    this.setState({
     message:"Your Data Has Updated Successfully"
      })
 
 if(this.state.submit==false && this.state.studentdata.student_name != ""  && this.state.studentdata.phone_no != ""){
   this.setState({
     studentname:this.state.studentdata.student_name,
     updatingButton:true,
     })
  
    axios.post('/updateuserprofiledata', this.state.studentdata )
     .then(  (response) =>{
       console.log(response);
       this.setState({
         submit:true,
         updatingButton:false
           })
     })
     .catch(  (error)=> {
       console.log(error);
     });
 
 }
  
 
   }
     
   changePhotoAndUploadit = (e)=>{
    
    this.setState({photoupload:true,message:"Your Photo Has Uploaded"})
    var formData = new FormData();
   formData.append("image",e.target.files[0]);
   formData.append("id",this.state.studentdata.id);
    axios.post('/updateprofilepicture', formData )
    .then( (response) => {
      console.log(response );
      this.setState({photoupload:false,submit:true,studentdata:{...this.state.studentdata,profile_image:response.data.thumb} })
    
    })
    .catch(  (error) => {
      console.log(error);
    });

 
   }

   uploadfileHandler = () =>{
document.getElementById("uploadfile").click();
}
    render() {
        var heightRender  = this.state.heightdata.map(i=>{
            return   <option value={i}>{i}</option>
          }) ;

         

        return (<div class="container pt-4">
            {this.state.loading?<SpinnerLoad/>:
            <div class="row gutters">
            <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
            <div class="card h-100">
                <div class="card-body">
                    <div class="account-settings pt-5">
                        <div class="user-profile">
                            <div class="user-avatar">
                            
                               { this.state.photoupload?<SpinnerLoad/> :<div> <img   id="imageUpload" src={this.state.studentdata.profile_image} alt="Maxwell Admin"/>
                                <span style={{"font-size":"15px"}} onClick={this.uploadfileHandler}>Edit</span> </div>}
                               
                            </div>
                         
                            <input id="uploadfile" type="file" onChange={this.changePhotoAndUploadit}  hidden/>
                            <h5 class="user-name">{this.state.studentdata.student_name}</h5>
                            <h6 class="user-email">{this.state.studentdata.student_email}</h6>
                        </div>
                        {/* <div class="about">
                            <h5>About</h5>
                            <p>I'm Yuki. Full Stack Designer I enjoy creating user-centric, delightful and human experiences.</p>
                        </div> */}
                    </div>
                </div>
            </div>
            </div>
            <div class="col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12">
            <div class="card h-100">
                <div class="card-body">
           
                <form>
              
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="first">Name</label>
                    <input onChange={this.inputBoxChangeHandler}   value={this.state.studentdata.student_name}     type="text" class="form-control" placeholder="Name" id="student_name"  />
                  </div>
                </div>
              
                <div class="col-md-6">
        
                  <div class="form-group">
                    <label for="phone">Phone Number</label>
                    <input  onChange={this.inputBoxChangeHandler}  value={this.state.studentdata.phone_no}   type="tel" class="form-control" id="phone_no" placeholder="phone"  />
                  </div>
                </div>
               
              
              </div>
        
        
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="company">Department</label>
                    <select  onChange={this.inputBoxChangeHandler}  value={this.state.studentdata.department_id}  class="form-select" aria-label="Default select example" id="department_id"
                    >
                      <option value="2">Department of Civil Engineering</option>
                      <option value="1">Department of Electrical and Electronics Engineering (EEE)</option>
                      <option value="3">Department of Computer Science Engineering (CSE)</option>
                    </select>
                  </div>
        
        
                </div>
              
        
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="Number">Position</label>
                    <input onChange={this.inputBoxChangeHandler}  value={this.state.studentdata.merit_position}   type="number" class="form-control" id="merit_position" placeholder="position"/>
                  </div>
        
                </div>
              
              </div>
            
        
        
              <div class="row">
                <div class="col-md-6">
        
                  <div class="form-group">
                    <label for="email">Email address</label>
                    <input onChange={this.inputBoxChangeHandler}  value={this.state.studentdata.student_email}   type="email" class="form-control" id="student_email" placeholder="email"/>
                  </div>
                </div>
              
        
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="hometown">Hometown</label>
                    <input onChange={this.inputBoxChangeHandler}  value={this.state.studentdata.hometown}   type="text" class="form-control" id="hometown" placeholder="Hometown"/>
                  </div>
        
                </div>
              
              </div>
        
              <div class="row">
                <div class="col-md-6">
        
                  <div class="form-group">
                    <label for="school">School</label>
                    <input onChange={this.inputBoxChangeHandler}  value={this.state.studentdata.school}   type="text" class="form-control" id="school" placeholder="School"/>
                  </div>
                </div>
              
        
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="college">College</label>
                    <input onChange={this.inputBoxChangeHandler}  value={this.state.studentdata.college}   type="text" class="form-control" id="college" placeholder="College"/>
                  </div>
        
                </div>
              
              </div>
              <div class="row">
                <div class="col-md-6">
        
                  <div class="form-group">
                    <label for="bloodgroup">Blood Group</label>
                    <select onChange={this.inputBoxChangeHandler}   value={this.state.studentdata.bloodgroup}   class="form-select"  id="bloodgroup" aria-label="Default select example">
                      <option value="O+">O+</option>
                      <option value="O-">O-</option>
                      <option value="A+">A+</option>
                      <option value="A-">A-</option>
                      <option value="B+">B+</option>
                      <option value="B-">B-</option>
                      <option value="AB+">AB+</option>
                      <option value="AB-">AB-</option>
        
                    </select>
                  </div>
                </div>
              
        
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="height">Height</label>
                    {/* <input  onChange={this.inputBoxChangeHandler}  value={this.state.studentdata.height}  type="text" class="form-control" id="height" placeholder="Height"/> */}




                    <select onChange={this.inputBoxChangeHandler}   value={this.state.studentdata.height}   class="form-select"  id="height" aria-label="Default select example">
           {heightRender}
                     
          </select>


                  </div>
        
                </div>
              
              </div>
              <div class="row">
                <div class="col-md-6">
        
                  <div class="form-group">
                    <label for="School">Emargency Contact</label>
                    <input onChange={this.inputBoxChangeHandler}  value={this.state.studentdata.emergency_contact}   type="text" class="form-control" id="emergency_contact" placeholder="Emargency Contact"/>
                  </div>
                </div>
              
        
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="batch_no">Batch</label>
                    {/* <input onChange={this.inputBoxChangeHandler}  value={this.state.studentdata.batch_no}   type="text" class="form-control" id="batch_no" placeholder="Batch"/> */}

                    <select onChange={this.inputBoxChangeHandler}   value={this.state.studentdata.batch_no}   class="form-select"  id="batch_no" aria-label="Default select example">
                      <option value="1">1'th</option>
                      <option value="2">2'th</option>
                      <option value="3">3'th</option>
                      <option value="4">4'th</option>
                      <option value="5">5'th</option>
                      <option value="6">6'th</option>
                      <option value="7">7'th</option>
                      <option value="8">8'th</option>
                      <option value="9">9'th</option>
                      <option value="10">10'th</option>
                      <option value="11">11'th</option>
                      <option value="12">12'th</option>
                      <option value="13">13'th</option>
                      <option value="14">14'th</option>
                      <option value="15">15'th</option>
                     </select>
 </div>
        
                </div>
              
              </div>

              


              {/* <div class="col-md-6">
        
        <div class="form-group">
          <label for="School">Date Of Birth</label>
          <input onChange={this.inputBoxChangeHandler}  value={this.state.studentdata.dateofbirth}   type="date" class="form-control" id="dateofbirth" placeholder="Emargency Contact"/>
        </div>
      </div> */}
          <div class="row">
              <div class="col-md-6">
        <div class="form-group">
          <label for="School">Date Of Birth</label>
          <input onChange={this.inputBoxChangeHandler}  value={this.state.studentdata.dateofbirth}   type="date" class="form-control" id="dateofbirth" placeholder="Emargency Contact"/>
        </div>
      </div>

      <div class="col-md-6">
      <div class="form-group">
                    <label for="sex">Sex</label>
                    <select onChange={this.inputBoxChangeHandler}   value={this.state.studentdata.sex}   class="form-select"  id="sex" aria-label="Default select example">
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                      <option value="Trans">Trans</option>
                      
        
                    </select>
                  </div></div></div>


                  <div class="row">
       <div class="col-md-6">
      <div class="form-group">
                    <label for="hall_no">Hall No</label>
                    <select onChange={this.inputBoxChangeHandler}   value={this.state.studentdata.hall_no}   class="form-select"  id="hall_no" aria-label="Default select example">
                    <option value="0">No Hall</option>
                      <option value="1">Omar Akushey Hall</option>
                      <option value="2">Muktijoddha Hall</option>
                      <option value="3">Taramon Bibi Hall</option>
                      
        
                    </select>
                  </div></div></div>           




      <div class="row pt-5">
                <div class="col-md-6">
        
                  <div class="form-group">
                    <label for="email">Email address</label>
                    <input onChange={this.inputBoxChangeHandler}  value={this.state.studentdata.student_email}   type="email" class="form-control" id="student_email" placeholder="email"/>
                  </div>
                </div>
              
        
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="password">Password</label>
                    <input onChange={this.inputBoxChangeHandler}    type="text" class="form-control" id="password" placeholder="Password"/>
                  </div>
        
                </div>
              
              </div>
        
        
        
        
              {this.state.submit?<div class="position-fixed bottom-0 end-0 p-3" style={{"z-index": "11"}}>
  <div id="liveToast" class="toast  show" role="alert" aria-live="assertive" aria-atomic="true">
    <div class="toast-header">
      <img src={meclogo} style={{'width':'80px'}} class="rounded me-2" alt="..."/>
      <strong class="me-auto"> </strong>  
       <button onClick={this.toastHideController} type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
    </div>
    <div class="toast-body">
   {this.state.message}
    </div>
  </div>
</div>:"" }

               

 
              <div class="d-flex justify-content-center pt-3">
    
<button class="btn btn-primary" onClick={this.formSubmitHandler} type="submit" disabled={this.state.submit || this.state.updatingButton?true:false}>
{this.state.updatingButton?<span><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>Updating Profile..</span>:"Update Profile"}
  </button>
        
              
              </div>
        
            </form>



                </div>
            </div>
            </div>
            </div>
            }</div>
        )
    }
}

export default StudentProfileEditable
