import React, { Component } from 'react';
import './SpinnerLoad.css'

export class SpinnerLoad extends Component {
    render() {
        return (
            <div class="loader">{this.props.children}</div>
        )
    }
}

export default SpinnerLoad
