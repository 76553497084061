import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App';
import axios from 'axios';
import * as serviceWorker from './serviceWorker';

import reportWebVitals from './reportWebVitals';
//  axios.defaults.baseURL = "http://27.123.254.221/mecstudentapi/public/api";
  // axios.defaults.baseURL = "http://localhost/mecstudentapi/public/api";
  axios.defaults.baseURL = "https://api.greepie.com/public/api";
axios.defaults.headers.common['Authorization'] = 'Bearer '+localStorage.getItem("TOKEN_KEY");
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
serviceWorker.register();

