import React, { Component } from 'react'
import navbarlogo from './headerlogo.png';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";
 
 
export class NavBar extends Component {

  state={
    isLogin:localStorage.getItem("TOKEN_KEY")
  }
    dropDownMenuHandler = (e) =>{
         e.target.parentElement.children[1].classList.toggle("show");
  }

  navBarHamburgerHandler = () =>{
    document.querySelector("#navbarNavAltMarkup").classList.toggle("collapse");
  }

  navBarhandler = () =>{
   
  document.querySelector("#navbarNavAltMarkup").classList.toggle("collapse");
 
  }

    render() {
   
   
        return (
            <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="container-fluid">
      <Link class="navbar-brand" to="/"> <img class="img-fluid" style={{"width": "120px"}} src={navbarlogo} alt=""/> </Link> 
      <button onClick={this.navBarHamburgerHandler} class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
        <div class="navbar-nav">
          <Link class="nav-link fw-bold " onClick={this.navBarhandler}   to="/">Home</Link>
          {/* <Link class="nav-link fw-bold"   onClick={this.navBarhandler}  to="/student/add">Add Student</Link> */}
          {/* <Link class="nav-link fw-bold"   onClick={this.navBarhandler}  to="/students">Students</Link> */}
          {this.props.islogin==false?<Link class="nav-link fw-bold"   onClick={this.navBarhandler}  to="/student/add">Register</Link>:""}


          {this.props.islogin?<Link class="nav-link fw-bold"   onClick={this.navBarhandler}  to="/profile">Profile</Link>:""}
          {this.props.islogin==false?<Link class="nav-link fw-bold"   onClick={this.navBarhandler}  to="/login">Login</Link>:""}
          {this.props.islogin?<Link class="nav-link fw-bold cursor: pointer"   onClick={this.props.islogOut}  to="/login">Logout</Link>:""}
           
          {/* <Link class="nav-link fw-bold"   onClick={this.navBarhandler}  to="/login"></Link> */}
          
          
          {/* <li class="nav-item dropdown">
            <button style={{"border":"none", "outline":"none", "background":"none"}} class="nav-link dropdown-toggle" onClick={this.dropDownMenuHandler}  href="#" id="navbarDropdownMenuLink" role="button"
              data-bs-toggle="dropdown" aria-expanded="false">
              Department
            </button>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
              <li><Link class="dropdown-item" to="/department/civil">Department of Civil Engineering (CE)</Link></li>
              <li><Link class="dropdown-item" to="/department/eee">Department of Electrical and Electronics Engineering (EEE)</Link></li>
              <li><Link class="dropdown-item" to="/department/cse">Department of Computer Science Engineering (CSE)</Link></li>
             
            </ul>
          </li> */}
        </div>
      </div>
    </div>
  </nav>  
 
        )
    }
}

export default NavBar
