import { initializeApp } from 'firebase/app';

// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
  apiKey: "AIzaSyBiIS3bSmE_ELNVQiDTOOPCAdR1XZk4Yxc",
  authDomain: "mecstudent-26f67.firebaseapp.com",
  projectId: "mecstudent-26f67",
  storageBucket: "mecstudent-26f67.appspot.com",
  messagingSenderId: "729385708703",
  appId: "1:729385708703:web:3464b072d7b56d68d746aa",
  measurementId: "${config.measurementId}"
};

const app = initializeApp(firebaseConfig);
export default app;