import React from 'react'
import RoutesManage from './RoutesManage';
import { useEffect } from 'react';
 
import app from './firebase';
import { getMessaging,getToken,onMessage } from "firebase/messaging";
import { onBackgroundMessage } from "firebase/messaging/sw"
import {isLogin} from './Component/utils/index';
import axios from 'axios';

 function App() {
  


  

  useEffect(() => {
 
    const messaging = getMessaging(app);
    onMessage(messaging, (payload) => {
      console.log('Message received. ', payload);
      // ...
    });
  
   getToken(messaging, { vapidKey: 'BEmSQ2Co6O1-aVbxO6iQyLIMUpdgF0aP6s5CD3Xo5zhY07I36wPaZYkS2v0lLNMokf7OtQPFuM_CjVdAWyQmgM4' }).then((currentToken) => {
     if (currentToken) {
       console.log(currentToken)
       axios.post('/push/token', {token:currentToken} )
       .then(  (response) =>{
         console.log(response);
      
       })
       .catch(  (error)=> {
         console.log(error);
       });
  
     } else {
     
       console.log('No registration token available. Request permission to generate one.');
   
     }
   }).catch((err) => {
     console.log('An error occurred while retrieving token. ', err);
  
   });
  
  
 
  // if (window.Notification && Notification.permission === "granted") {
  //   const options = {
  //     body: "Testing Our Notification",
  //     icon: "/icons/icon.png"
  //   };
  // console.log("push")
  //   new Notification("Hi there!!", options);
  // } 

 

  // else if (window.Notification && Notification.permission !== "denied") {
  //   Notification.requestPermission(status => {
  //     if (status === "granted") {
  //       const options = {
  //         body: "Testing Our Notification",
  //         icon: "/icons/icon.png"
  //       };
      
  //       new Notification("Hi there!!", options);
  //     } else {
  //       alert("You denied or dismissed permissions to notifications.");
  //     }
  //   });
  // } else {
  //   alert(
  //     "You denied permissions to notifications. Please go to your browser or phone setting to allow notifications."
  //   );
  // } 


  });
  return (
    <div>
      <RoutesManage/>
    </div>
  )
}
export default App;