import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';
import axios from 'axios';
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery'; 
import 'datatables.net-rowreorder';
import 'datatables.net-responsive';
import SpinnerLoad from './../../Component/SpinnerLoad/SpinnerLoad';
export class StudentPage extends Component {

  state = {
    studentsData:"",
    loading:true,
    department:{
      1:'EEE',
      2:'CE',
      3:'CSE'
     },
  }



  componentDidMount() {


    axios.get('/student/all')
  .then( (response)=> {
    console.log(response.data.data);
    this.setState({studentsData:response.data.data,
  });

    $(document).ready(function () {
      $('#example').DataTable( {
        rowReorder: {
            selector: 'td:nth-child(2)'
        },
        responsive: true
       } 
       );
  });
  this.setState({  loading:false})
  })
  .catch(  (error)=> {
    console.log(error);
  })
  }

    render() {

      const studentData = this.state.studentsData;
       
   var tableData =   this.state.loading==false? Object.keys(studentData) .map(key=>{
     var dept_id = studentData[key].department_id;
return    <tr>
<td>{studentData[key].student_name!=null?studentData[key].student_name:"No Name"}</td>
<td>{studentData[key].department_id!=null?this.state.department[dept_id]:"No Department"}</td>
<td>{studentData[key].phone_no!=null?studentData[key].phone_no:"No Phone"}</td>
<td>{studentData[key].student_email!=null?studentData[key].student_email:"No Email"}</td>
<td>{studentData[key].hometown!=null?studentData[key].hometown:"No HomeTown"}</td>
<td>{studentData[key].batch_no!=null?studentData[key].batch_no:"No Batch"}</td>
 
 
 
 
</tr>
   }):"";

   var tableMake = 
   <table id="example" class="display nowrap" style={{ width: '100%' }} >
   <thead>
       <tr>
         <th scope="col">Name</th>
          <th scope="col">Department</th>  
         <th scope="col">Phone Number</th>
         <th scope="col">Email</th>
         <th scope="col">Hometown</th>
        <th scope="col">Batch</th>
       </tr>
     </thead>
     <tbody>
       {tableData} 
     </tbody>
   </table>;
   
 
        return (
            <div class="container"style={{"padding-top": "10px"}}>
        <div class="pt-5 pb-5">
            {/* <h2 class="d-flex justify-content-center pt-2 pb-2">Students Information</h2> */}

           {this.state.loading?<SpinnerLoad/>:tableMake} 
       
        </div>


    </div>
        )
    }
}

export default StudentPage
